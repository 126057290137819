<div class="modal-dialog modal-xl modal-dialog-centered">
  <div class="modal-content">
    <div class="container p-0">
      <div class="modal-header">
        <!-- 현재/전체 페이지 -->
        <div>
          <span class="page-label text-center d-inline-block">
            {{ currentIndex + 1 }}
          </span>

          <span class="page-label text-center d-inline-block"> / </span>

          <span class="page-label text-center d-inline-block">
            {{ dialogRef.data!.imgUrlList.length }}
          </span>
        </div>

        <!-- 모달 닫기 버튼 -->
        <button
          class="btn-close"
          type="button"
          (click)="onCloseClick()"
        ></button>
      </div>

      <div class="modal-body p-0 p-md-3">
        <!-- 이미지 캐러셀 -->
        <div
          #mainImgCarousel
          id="mainImgCarousel"
          class="carousel slide mb-3 bg-black"
        >
          <!-- 캐러셀 이미지 목록 -->
          <div class="carousel-inner">
            @for (
              imgUrl of dialogRef.data!.imgUrlList;
              track imgUrl;
              let i = $index
            ) {
              <div
                class="carousel-item"
                [class.active]="i === dialogRef.data!.index"
              >
                <div class="ratio ratio-16x9">
                  <img
                    class="w-100 h-100 object-fit-contain"
                    [src]="dialogRef.data!.imgUrlList[i]"
                  />
                </div>
              </div>
            }
          </div>

          <!-- 캐러셀 이전 이미지 버튼 -->
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#mainImgCarousel"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>

          <!-- 캐러셀 다음 이미지 버튼 -->
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#mainImgCarousel"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        <div class="overflow-x-scroll pb-2">
          <!-- 이미지 썸네일 목록 -->
          <ul class="img-container p-0 m-0">
            @for (
              imgUrl of dialogRef.data!.imgUrlList;
              track imgUrl;
              let i = $index
            ) {
              <li class="d-inline me-2" role="button" (click)="onImgClick(i)">
                <img
                  class="thumbnail img object-fit-cover rounded"
                  [class.border]="i === currentIndex"
                  [class.border-3]="i === currentIndex"
                  [class.border-primary]="i === currentIndex"
                  [src]="imgUrl"
                />
              </li>
            }
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
